import React from "react";
import CookieConsent from "react-cookie-consent";
import "prismjs/prism";
import "prismjs/components/prism-java";
import "prismjs/components/prism-javascript";

import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/style.less";
import "prismjs/themes/prism-okaidia.css";

export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
  </>
);
